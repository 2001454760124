



















































































import { Component, Mixins } from 'vue-property-decorator';
import { ReportChartLineComponent, ReportChartRingComponent } from '@/mixins/report-chart-base';
import EnergyIndexService from './service/energy-index';
import { EnergyElectricityRtsQueryModel } from './model/energy-electricity-rts-model';

@Component
export default class EnergyIndexComponent extends Mixins(ReportChartRingComponent, ReportChartLineComponent) {
    headerCardList: Array<any> = [
        { title: '今天用电', count: null, unit: 'kWh', loading: true, icon: '/img/day-bar.png' },
        { title: '本月用电', count: null, unit: 'kWh', loading: true, icon: '/img/month-bar.png' },
        { title: '今年用电', count: null, unit: 'kWh', loading: true, icon: '/img/year-bar.png' },
        { title: '正在告警', count: null, unit: '条', loading: true, icon: '/img/alarm.png' },
        { title: '异常设备', count: null, unit: '台', loading: true, icon: '/img/fault.png' }
    ];

    electricityRtsData: any = {};
    electricityRtsDataLoading: boolean = true;

    electricityItemsData: any = null;
    electricityItemsDataLoading: boolean = true;

    electricityMonthData: any = {};
    electricityMonthDataLoading: boolean = true;

    alarmStatusCountData: any = {};
    alarmStatusCountDataLoading: boolean = true;

    created() {
        this.initData();
    }

    initData() {
        EnergyIndexService.getElectricityCount().then(res => {
            this.headerCardList[0].count = res.dayCount;
            this.headerCardList[1].count = res.monthCount;
            this.headerCardList[2].count = res.yearCount;
        }).finally(() => {
            this.headerCardList[0].loading = false;
            this.headerCardList[1].loading = false;
            this.headerCardList[2].loading = false;
        });
        EnergyIndexService.getAlarmActiveCount().then(res => {
            this.headerCardList[3].count = res.activeCount;
        }).finally(() => {
            this.headerCardList[3].loading = false;
        });
        EnergyIndexService.getFacilityCount().then(res => {
            this.headerCardList[4].count = res.faultCount;
        }).finally(() => {
            this.headerCardList[4].loading = false;
        });

        EnergyIndexService.getElectricityRtsData(new EnergyElectricityRtsQueryModel().toService()).then(res => {
            this.electricityRtsData = res;
        }).finally(() => this.electricityRtsDataLoading = false);

        EnergyIndexService.getElectricityItemsData({ intervalType: 'DAY' }).then(res => {
            this.electricityItemsData = res;
        }).finally(() => this.electricityItemsDataLoading = false);

        EnergyIndexService.getElectricityMonthData().then(res => {
            this.electricityMonthData = res;
        }).finally(() => this.electricityMonthDataLoading = false);

        EnergyIndexService.getAlarmStatusCount().then(res => {
            this.alarmStatusCountData = res;
        }).finally(() => this.alarmStatusCountDataLoading = false);
    }

    async electricityItemChange(ev: any) {
        const params = {
            intervalType: _.get(ev, 'target.value')
        };
        EnergyIndexService.getElectricityItemsData(params).then(res => {
            this.electricityItemsData = res;
        }).finally(() => this.electricityItemsDataLoading = false);
    }
}
